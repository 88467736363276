import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  BenefitsComponent,
  Box,
  CareerCardContainer,
  CareersCultureHero,
  CareersForm,
  EventsComponent,
  GenericComponent,
  Hero,
  HotJobsComponent,
  Ram,
  ResponsiveContext,
} from '../../ui';
import htmlSerializer from '../../prismic/htmlSerializer';
import Layout from '../../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allCareerss {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
            cta_title
            cta_label
            cta_link {
              ... on PRISMIC__ExternalLink {
                target
                _linkType
                url
              }
            }
            why_header
            why_copy
            why_proof_1_header
            why_proof_1_copy
            why_proof_1_image
            why_proof_2_header
            why_proof_2_copy
            why_proof_2_image
            why_proof_3_header
            why_proof_3_copy
            why_proof_3_image
            culture_header
            culture_copy
            culture_ctalabel
            culture_ctalink {
              ... on PRISMIC__ExternalLink {
                target
                _linkType
                url
              }
            }
            culture_image
            benefits_header
            benefits_copy
            benefits {
              title
              text
              contact_email
              image
              document {
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
            benefits_iconography {
              text
              icon
            }
            jobs {
              title
              location
              description
              url
            }
          }
        }
      }
    }
    events: allUpcomingEvent {
      nodes {
        id
        uid
        date
        title
        location
        summary
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const whyCards = [
    {
      id: 'whyCards-0',
      title: content.why_proof_1_header,
      blurb: content.why_proof_1_copy,
      image: content.why_proof_1_image,
    },
    {
      id: 'whyCards-1',
      title: content.why_proof_2_header,
      blurb: content.why_proof_2_copy,
      image: content.why_proof_2_image,
    },
    {
      id: 'whyCards-2',
      title: content.why_proof_3_header,
      blurb: content.why_proof_3_copy,
      image: content.why_proof_3_image,
    },
  ];
  const benefits = content.benefits.map((item, index) => {
    return {
      id: `benefits-${index}`,
      title: item.title,
      description: item.text,
      contactEmail: item.contact_email,
      image: item.image,
      document: item.document,
    };
  });
  const benefitsIcons = content.benefits_iconography.map((item, index) => {
    return {
      id: `benefitsIcons-${index}`,
      text: item.text,
      icon: item.icon.url,
    };
  });
  const events = data.events.nodes.map((item) => {
    return {
      id: item.id,
      eventDate: item.date,
      eventTitle: item.title,
      eventDescription: item.summary,
      eventLocation: item.location,
      eventUrl: item.uid && `/library/${item.uid}`,
    };
  });
  const jobs = content.jobs.map((item, index) => {
    const id = `jobs-${index}`;

    return {
      id,
      jobTitle: item.title,
      location: item.location,
      description: item.description,
      url: item.url,
    };
  });

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.hero_image && content.hero_image.url}
    >
      <Hero
        imageMobile={
          content.hero_image && content.hero_image.mobile && content.hero_image.mobile.url
        }
        imageDesk={content.hero_image && content.hero_image.url}
        heroHeader={content.hero_header}
        heroCopy={content.hero_copy}
        htmlSerializer={htmlSerializer}
      />

      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <Box>
              <CareersForm
                Link={Link}
                size={size}
                header={content.cta_title[0]?.text || 'EXPLORE YOUR OPPORTUNITIES WITH POWER'}
                ctaLabel={content.cta_label[0]?.text || 'Search'}
                ctaLink={
                  content.cta_link?.url ||
                  'https://phh.tbe.taleo.net/phh04/ats/careers/jobSearch.jsp?act=redirectCws&cws=1&org=POWERENGINEERS'
                }
              />
              <CareerCardContainer
                Link={Link}
                size={size}
                header={content.why_header}
                blurb={content.why_copy}
                cards={whyCards}
                htmlSerializer={htmlSerializer}
              />
              <CareersCultureHero
                size={size}
                header={content.culture_header}
                blurb={content.culture_copy}
                cultureCtaLabel={content.culture_ctalabel[0]?.text}
                cultureCtaLink={content.culture_ctalink?.url}
                background={content.culture_image.url}
                htmlSerializer={htmlSerializer}
              />

              <BenefitsComponent
                size={size}
                header={content.benefits_header}
                blurb={content.benefits_copy}
                benefits={benefitsIcons}
                htmlSerializer={htmlSerializer}
              />
              <GenericComponent
                size={size}
                cardInformation={benefits}
                htmlSerializer={htmlSerializer}
              />
              <Ram color="CB361D" />
              <EventsComponent Link={Link} size={size} header="Upcoming Events" events={events} />
              <HotJobsComponent
                Link={Link}
                size={size}
                header="Hot Jobs"
                jobs={jobs}
                htmlSerializer={htmlSerializer}
              />
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
